import api from '@/api'

export default {
  data () {
    return {
      timeoutIdsMap: {}
    }
  },
  methods: {
    debounce (timeOutProperty, callback) {
      if (this.timeoutIdsMap[timeOutProperty]) {
        clearTimeout(this.timeoutIdsMap[timeOutProperty])
      }
      this.timeoutIdsMap[timeOutProperty] = setTimeout(() => {
        callback()
      }, 500)
    },
    m$refetchTask ({service, taskCategory, data}) {
      this.$events.emit(`${service}:${taskCategory}:refetchTask`, data)
    },
    setFieldStateSaving(target) {
      if (!target) {
        return
      }
      target.classList.add('saving-field')
    },
    setFieldStateSaved(target) {
      if (!target) {
        return
      }
      target.classList.remove('saving-field')
      target.classList.add('field-saved')
      setTimeout(() => target.classList.remove('field-saved'), 1500)
    },
    setFieldStateError(target) {
      if (!target) {
        return
      }
      target.classList.remove('saving-field')
      target.classList.remove('field-saved')
      target.classList.add('field-error')
      setTimeout(() => target.classList.remove('field-error'), 3600)
    },
    m$submitField ({taskId, service, taskCategory, data, target}) {
      this.setFieldStateSaving(target)

      api.request(service, 'patch', `tasks/${taskId}`, data)
        .then(() => {
          this.$notify({
            type: 'success',
            text: 'Zapisano dane formularza'
          })
          this.setFieldStateSaved(target)
          this.debounce('refetch',() => this.m$refetchTask({service, taskCategory, data}))
        })
        .catch((e) => {
          this.setFieldStateError(target)
          this.toggleLoading()
          this.errorNotify(e)
          this.$notify({
            type: 'error',
            text: 'Błąd zapisywania danych formularza'
          })
          // this.m$refetchTask({service, taskCategory, data})
        })
    },
  }
}
