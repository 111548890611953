const isValueFalsyNotFalse = (value) => {
  return (value === null || value === undefined || value === '' || (Array.isArray(value) && value.length === 0))
}

const defaultValidator = (value, field, model) => {
    if (isValueFalsyNotFalse(value) && field.required()) {
      return ['']
    } else return []
}
const selectValidator = (value, field, model) => {
  if (isValueFalsyNotFalse(value) && field.required()) {
      return ['']
    } else return []
}
const collectionValidator = (value, field, model) => {
    if (!field.isValid) {
      return ['']
    } else return []
}

const collectionOnValidate = function (model, errors, schema) {
  if (errors.length > 0) {
    // console.log(this)
  }
}
const defaultOnValidate = function (model, errors, schema) {
  if (errors.length > 0) {
    // console.log(this)
  }
}

const validators = {
  select: selectValidator,
  customSelect: selectValidator,
  collection: collectionValidator,
  newCollection: collectionValidator,
  defaultValidator: defaultValidator
}
const onValidate = {
  collection: collectionOnValidate,
  newCollection: collectionOnValidate,
  defaultOnValidate: defaultOnValidate
}

const prepareValidatorByType = (field) => {
  return validators.hasOwnProperty(field.type) ? validators[field.type] : validators.defaultValidator
}
const prepareOnValidateByType = (field) => {
  return onValidate.hasOwnProperty(field.type) ? onValidate[field.type] : onValidate.defaultOnValidate
}
export {isValueFalsyNotFalse, prepareValidatorByType, prepareOnValidateByType}
