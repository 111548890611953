import { render, staticRenderFns } from "./InvoicesAppraisal.vue?vue&type=template&id=227ffc29&scoped=true&"
import script from "./InvoicesAppraisal.vue?vue&type=script&lang=js&"
export * from "./InvoicesAppraisal.vue?vue&type=script&lang=js&"
import style0 from "./InvoicesAppraisal.vue?vue&type=style&index=0&id=227ffc29&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "227ffc29",
  null
  
)

export default component.exports