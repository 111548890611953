<template>
  <div class="" v-if="realEstateData">
    <label>Adres nieruchomości:</label>
    <div
         :key="`address-${addressIndex}`"
         v-for="(address, addressIndex) in realEstateData"
    >
      <div id="realEstate-data">
        <ul class="list-group">
          <li
            class="list-group-item list-group-item-overflowed"
            :key="`address-${addressIndex}-addressField-${addressFieldIndex}`"
            v-for="(addressField, addressFieldIndex) in filterAddressFields(address)"
          >
            <label>{{ addressFieldIndex }}:</label>
            {{ addressField || 'b.d.' }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import addressesMixin from '../addressesMixin.js'

export default {
name: "RealEstateAddresses",
  mixins: [addressesMixin],
  props: {
  realEstateData: {}
  }
}
</script>

<style scoped>
.list-group-item-overflowed {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
