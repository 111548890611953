<template>
  <div>
    <template v-for="(element, index) in value">
      <div
        :key="`${getFieldID(schema)}-${index}`"
        class="multiinput-element-container"
      >
        <input
          class="form-control"
          :id="`${getFieldID(schema)}-${index}`"
          :type="schema.inputType"
          :value="element"
          @change="onBlur"
          @input="(e) => handleInput(e, index)"
          @keydown="filterKey"
        />
        <div
          v-if="value.length > 1"
          type="button"
          class="multiinput-action-button button-delete"
          @click="() => remove(index)"
        />
      </div>
    </template>
    <div v-if="value.length > 0 && !value.includes('') ">
      <div
        type="button"
        class="multiinput-action-button button-add"
        @click="add"
      />
      <span class="button-add-label"> {{this.schema.settings.addButtonLabel ? this.schema.settings.addButtonLabel : 'Dodaj'}} </span>
    </div>
  </div>
</template>

<script>
import { abstractField } from "vue-form-generator";

export default {
  name: "fieldMultiInput",
  mixins: [abstractField],
  beforeUpdate(){
    this.correctedValue()
  },
  created() {
    this.correctedValue()
  },
  methods: {
    correctedValue() {
      if(!this.value || (Array.isArray(this.value) && this.value.length === 0))
      {this.value = [""]}
    },
    formatValueToModel(value) {
      return value;
    },
    filterKey(e) {
      const key = e.key;
      if ((key === "." || key === ",") && this.schema.model === "floors") {
        return e.preventDefault();
      } else "";
    },

    handleInput(e, index) {
      this.value[index] = e.target.value;
      this.$emit("model-updated", this.value, this.schema.model, this.$el);
      return;
    },
    add() {
      this.value.push("");
    },
    remove(valIndex) {
      this.value = this.value.filter((value, index) => index !== valIndex);
      this.$emit("model-updated", this.value, this.schema.model, this.$el);
      this.onBlur(this);
    },
  },
};
</script>

<style scoped lang="scss">
.multiinput-action-button {
  position: relative;
  width: 21px;
  height: 21px;
  left: -1rem;
  z-index: 3;
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
.button-delete {
  top: 0.6rem;
  left: 1.1rem;
  background-color: $errorColor;
}

.button-add {
  top: 1.95rem;
  background-color: $successColor;
}

.button-delete:before,
.button-delete:after,
.button-add:before,
.button-add:after {
  content: "";
  display: block;
  width: 12px;
  height: 1px;
  background-color: #242a3c;
  position: absolute;
  top: 10px;
  left: 5px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.button-delete:after,
.button-add:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.button-delete:hover,
.button-add:hover {
  background-color: #fff;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.button-add::after {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.button-add::before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.button-add:hover {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

.button-add-label {
  border: 1px solid rgba(66, 165, 246, 0.2);
  border-radius: 4px;
  padding: 10px 20px;
}
.multiinput-element-container {
  display: flex;
  flex-direction: row-reverse;
}
</style>
