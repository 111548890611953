<template>
  <div>
    <blocking-loader  :show="loaded === false">Przygotowywanie Formularza</blocking-loader>
    <div v-if="modelLoaded && loaded && categoryGroup === 'main'">
      <CommonForm
        :rawSchemas="rawSchemas"
        :model="model"
        :id="`main`"
        :rules="rules"
        @saveField="handleSaveField"
        @addCollectionItem="handleAddCollectionItem"
        @removeCollectionItem="handleRemoveCollectionItem"
        :schemaType="SCHEMA_NAME_MAIN_VALUATION_comp"
      >
      </CommonForm>
    </div>
    <Tabs
      v-if="loaded && categoryGroup === 'appraisal'"
      :tabs-data="schemaTabs"
      ref="formTabs"
      class="task-form"
      :key="`realEatatesTabs-${realEstates.length}`"
      v-on:clickedTab="reactToTabChange"
      :allow-delete="true"
      @tabs-delete-item="handleDeleteRealEstate"
      :topDistance="topTabsDistance + 44"
    >
      <div v-for="(realEstate, index) in realEstates" :slot="`form-${realEstate.id}-${index}` "
           :key="`realEstate-${realEstate.id}-${index}`" v-show="activeTab === `form-${realEstate.id}-${index}`">
        <real-estate
          v-if="loaded"
          :id="`realEstateContainer-${realEstate.id}-${index}`"
          :realEstate="realEstate"
          :model="model"
          :rawSchemas="rawSchemas"
          :rules="rules"
          :service="service"
          :currently-opened="currentlyOpened(index)"
          :real-estate-index="index"
          :topTabsDistance="topTabsDistance"
          @delete-land-register="(e) => $emit('delete-land-register', e)"
          @addLandRegisterCall="addLandRegisterTransReceiver"
          @saveField="handleSaveField"
          @addCollectionItem="handleAddCollectionItem"
          @removeCollectionItem="handleRemoveCollectionItem"
        ></real-estate>
      </div>
    </Tabs>
  </div>

</template>
<script>
import BlockingLoader from '@/components/share/BlockingLoader.vue'
import Tabs from '@/components/share/tabs/Tabs.vue'
import RealEstate from '../form/forms/realEstate.vue'
import {
  FORM_EVENT_ADD_COLLECTION_ITEM, FORM_EVENT_REMOVE_COLLECTION_ITEM,
  FORM_EVENT_SAVE_FIELD
} from '../form/utils/formEventsConstants.js'
import CommonForm from '../form/forms/CommonForm.vue'
import {SCHEMA_NAME_MAIN_VALUATION} from '../form/forms/schemaNames.js'

export default {
  components: {
    CommonForm,
    BlockingLoader,
    RealEstate,
    Tabs
  },
  mixins: [],
  props: {
    model: {},
    rawSchemas: {},
    rules: {},
    categoryGroup: {type: String, required: true},
    service: {},
    addLandRegisterCaller: {},
    schema: {},
    rulesTemp: {},
    modelLoaded: {},
    topTabsDistance: {type: Number, required: true},
  },
  data () {
    return {
      schemaTabs: [],
      loaded: false
    }
  },
  computed: {
    activeTab () {
      return this.schemaTabs.find(tab => tab[2] === true)[0]
    },
    realEstates () {
      return this.model.hasOwnProperty('realEstates') && Array.isArray(this.model.realEstates) && this.model.realEstates || []
    },
    SCHEMA_NAME_MAIN_VALUATION_comp () {
      return SCHEMA_NAME_MAIN_VALUATION
    }
  },
  watch: {
    realEstates(prev, next) {
      this.getTabs()
    }
  },
  async mounted () {
    this.getTabs()
    this.loaded = true
  },
  methods: {
    handleSaveField (e) {
      this.$emit(FORM_EVENT_SAVE_FIELD, e)
    },
    handleAddCollectionItem (e) {
      this.$emit(FORM_EVENT_ADD_COLLECTION_ITEM, e)
    },
    handleRemoveCollectionItem (e) {
      this.$emit(FORM_EVENT_REMOVE_COLLECTION_ITEM, e)
    },
    currentlyOpened (realEstateIndex) {
      return this.schemaTabs.find((el, index)=> index === realEstateIndex)[2]
    },
    getTabs () {
      let currentTab = this.schemaTabs.findIndex((el, index) => el[2] === true)
      let currentTabIndex = currentTab === -1 ? 0 : currentTab

      this.schemaTabs = [
        ...(this.realEstates.map((el,index) => {
          return [
            `form-${el.id}-${index}`,
            `Nieruchomość ${el.id ? el.id : ''}`,
            index === currentTabIndex,
            [`${this.service}.get_task__appraisal__1`],
            [true]
          ]
        }))
      ]
    },
    handleDeleteRealEstate ({tabIndex}) {
      this.$emit('delete-real-estate', {realEstate: this.realEstates[tabIndex]})
    },
    addLandRegisterTransReceiver () {
      let realEstateIndex = this.schemaTabs.findIndex(el => el[2] === true)
      if (!this.currentlyOpened(realEstateIndex)) {
        return
      }
      this.addLandRegisterCaller({realEstateIndex})
      this.getTabs()
    },
    reactToTabChange (tabName) {
      this.schemaTabs = this.schemaTabs.map(tab => tab[0] === tabName ? (tab.map((tabProp, index) => index === 2 ? (tabProp = true) : tabProp)) : (tab.map((tabProp, index) => index === 2 ? (tabProp = false) : tabProp)))
    },
  }
}
</script>
<style>
</style>
