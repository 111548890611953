<template>
  <form class="bwn-form" v-if="schemaLoaded" @invalid="handleInvalidation">
    <vue-form-generator
      :ref="`${id}-form-ref`"
      :schema="schema"
      :model="model"
      :options="formOptions"
      :form-id="id"
      @field-updated="submitField"
      @collection-new-updated="submitCollection"
      @validated="handleValidation"
    ></vue-form-generator>
  </form>
</template>

<script>
import fieldCollection from '../type/fieldCollection.vue'
import Vue from 'vue'
import VueFormGenerator from 'vue-form-generator'
import VueMultiselect from 'vue-multiselect'
import buildSchemaMixin from '../forms/buildSchemaMixin.js'
import {
  FORM_EVENT_ADD_COLLECTION_ITEM, FORM_EVENT_REMOVE_COLLECTION_ITEM,
  FORM_EVENT_SAVE_FIELD
} from '../utils/formEventsConstants.js'
import _ from 'lodash'
import fieldMoney from '../../form/type/fieldMoney.vue'
import fieldCustomSelect from '@/components/bundles/share/form/type/fieldCustomSelect.vue'
import {FORM_REPLACE} from '../utils/jsonPatchContsants.js'
import fieldMultiInput from '@/components/bundles/share/form/type/fieldMultiInput.vue'

Vue.component('fieldNewCollection', fieldCollection)
Vue.component('multiselect', VueMultiselect)
Vue.component('fieldMoney', fieldMoney)
Vue.component('field-customSelect', fieldCustomSelect)
Vue.component('fieldMultiInput', fieldMultiInput)

Vue.use(VueFormGenerator)

export default {
  name: 'CommonForm',
  components: {
    'vue-form-generator': VueFormGenerator.component
  },
  mixins: [buildSchemaMixin],
  props: {
    id: {required: true},
    model: {type: Object, required: true, default: () => ({})},
    rules: {type: Object, default: () => ({})},
    rawSchemas: {type: Array, required: true, defualt: () => []},
    pathPrefix: {
      type: String, default: () => ''
    },
    pathPrefixIndexed: {
      type: String, default: () => ''
    },
    schemaType: {
      type: String, required: true
    }
  },
  created () {
    this.schema = this.prepareSchemas(this.rawSchemas, this.schemaType)
  },
  mounted () {
  },
  data () {
    return {
      schema: {},
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: false,
        validateAsync: false
      },
      errors: [],
      isValid: false
    }
  },
  methods: {
    handleInvalidation (e) {
      this.$el.dispatchEvent(new CustomEvent('highlightTab',{bubbles: true, detail: {class: 'danger'}}))
    },
    handleValidation (isValid, errors) {
      this.errors = errors
      this.isValid = isValid
      this.$emit('validated', isValid, errors, this)
    },
    modelUpdated (...e) {
      this.$emit('model-updated', e)
    },
    submitField (value, schema, target) {
      let data = {
        op: FORM_REPLACE,
        value: value,
        path: `${this.pathPrefix}/${schema.model}`,
        indexedPath: `${this.pathPrefixIndexed}/${schema.model}`,
        relatedFields: this.getRulesProperty(schema.model, 'relatedFields', []),
        target: target
      }
      this.$emit('saveField', data)
    },
    submitCollection (e) {
      let collectionEventsMap = {
        add: FORM_EVENT_ADD_COLLECTION_ITEM,
        replace: FORM_EVENT_SAVE_FIELD,
        remove: FORM_EVENT_REMOVE_COLLECTION_ITEM
      }
      e.path = `${this.pathPrefix}${e.path}`
      e.indexedPath = `${this.pathPrefixIndexed}${e.indexedPath}`
      if (_.get(e, 'colObject.schema.rulesPath', false)) {
        e.relatedFields = this.getRulesProperty(e.colObject.schema.rulesPath, 'relatedFields', [])
      }
      if (!e.hasOwnProperty('target') && e.hasOwnProperty('colObject')) {
        e.target = e.colObject.target
      }

      this.$emit(collectionEventsMap[e.op], e)
    }
  }
}
</script>

<style lang="scss">
form.bwn-form > .vue-form-generator fieldset > div.not-collection:nth-of-type(1) {
  display: none;
}

form.bwn-form > .vue-form-generator fieldset > div.not-collection:nth-of-type(2) {
  width: auto;
}

form.bwn-form > .vue-form-generator > div {
  width: 100%;
  float: none;
  break-inside: avoid;
  height: max-content;
  display: grid;
}

form.bwn-form > .vue-form-generator {
  column-count: 2;
  @media screen and (max-width: 1366px) {
    & {
      column-count: 1;
    }
  }
}
form.bwn-form .vue-form-generator fieldset .not-collection:nth-of-type(2) .field-newCollection .not-collection:nth-of-type(2) .field-wrap {
  padding-right: 0;
}

</style>
