<template>
  <div>
    <Tabs
      v-if="loaded"
      :tabs-data="realEstateTabs"
      ref="realEstateTabs"
      class="task-form"
      v-on:clickedTab="reactToTabChange"
      :key="`realEstateInternalTabs-${realEstateTabs.length}`"
      :allowDelete="true"
      @tabs-delete-item="handleDeleteLandRegister"
      :topDistance="topTabsDistance + 88"
    >
      <div v-if="loaded" slot="mainData">
        <CommonForm
          :rawSchemas="rawSchemas"
          :model="realEstate"
          :rules="rules"
          :id="`${id}`"
          @saveField="handleSaveField"
          @addCollectionItem="handleAddCollectionItem"
          @removeCollectionItem="handleRemoveCollectionItem"
          :schemaType="SCHEMA_NAME_APPRAISAL_REAL_ESTATE_comp"
          :path-prefix="`/realEstates/${realEstate.id}`"
          :path-prefix-indexed="`/realEstates/${realEstateIndex}`"
        >
        </CommonForm>
      </div>
      <div v-for="(landRegister, landRegisterIndex) in realEstate.landRegisters"
           :slot="`${realEstate.id}-landRegister-${landRegister.id}-${landRegisterIndex}`"
           :key="`${realEstate.id}-landRegister-${landRegister.id}-${landRegisterIndex}`"
      >
        <CommonForm
          :rawSchemas="rawSchemas"
          :model="landRegister"
          :rules="rules"
          :id="`${id}-land-register-${landRegister.id}-${landRegisterIndex}`"
          :currently-opened="currentlyOpenedRealEstateContentTab(landRegisterIndex)"
          @saveField="handleSaveField"
          @addCollectionItem="handleAddCollectionItem"
          @removeCollectionItem="handleRemoveCollectionItem"
          :schemaType="SCHEMA_NAME_APPRAISAL_LAND_REGISTER_comp"
          :path-prefix="`/realEstates/${realEstate.id}/landRegisters/${landRegister.id}`"
          :path-prefix-indexed="`/realEstates/${realEstateIndex}/landRegisters/${landRegisterIndex}`"
        >
        </CommonForm>
      </div>
    </Tabs>
  </div>
</template>

<script>
import Tabs from '@/components/share/tabs/Tabs.vue'
import {SCHEMA_NAME_APPRAISAL_REAL_ESTATE, SCHEMA_NAME_APPRAISAL_LAND_REGISTER} from '../forms/schemaNames.js'
import {
  FORM_EVENT_ADD_COLLECTION_ITEM, FORM_EVENT_REMOVE_COLLECTION_ITEM,
  FORM_EVENT_SAVE_FIELD
} from '../utils/formEventsConstants.js'
import CommonForm from '../forms/CommonForm.vue'

export default {
  name: 'realEstate',
  components: {
    CommonForm,
    Tabs
  },
  mixins: [],
  props: {
    id: {},
    realEstate: {},
    model: {},
    rawSchemas: {},
    rules: {},
    service: {},
    addLandRegisterCaller: {},
    currentlyOpened: {},
    topTabsDistance: {type: Number, required: true},
    realEstateIndex: {}
  },
  data () {
    return {
      loaded: true,
      realEstateTabs: [],
      schema: {}
    }
  },
  computed: {
    SCHEMA_NAME_APPRAISAL_LAND_REGISTER_comp () {
      return SCHEMA_NAME_APPRAISAL_LAND_REGISTER
    },
    SCHEMA_NAME_APPRAISAL_REAL_ESTATE_comp () {
      return SCHEMA_NAME_APPRAISAL_REAL_ESTATE
    },
    activeTab () {
      return this.realEstateTabs.find(tab => tab[2] === true)[0]
    },
    landRegisters () {
      return this.realEstate.hasOwnProperty('landRegisters') && Array.isArray(this.realEstate.landRegisters) && this.realEstate.landRegisters || []
    }
  },
  mounted () {
    this.$events.on(`${this.service}:schema:addLandRegisterTab`, this.addLandRegisterTab)
    this.getTabs()
  },
  watch: {
    landRegisters (newVal, oldVal) {
      this.getTabs()
    }
  },
  methods: {
    handleDeleteLandRegister ({tabIndex}) {
      let landRegisterToDelete = this.realEstate.landRegisters[tabIndex - 1]
      this.$emit('delete-land-register', {realEstate: this.realEstate, landRegisterToDelete})
    },
    currentlyOpenedRealEstateContentTab (realEstateContentTabIndex) {
      return realEstateContentTabIndex
    },
    addLandRegisterTab () {
      if (!this.currentlyOpened) {
        return
      }
      this.$emit('addLandRegisterCall')
      this.getTabs()
    },
    getTabs () {
      let currentTab = this.realEstateTabs.findIndex((el, index) => el[2] === true)
      let currentTabIndex = currentTab === -1 ? 0 : currentTab

      let realEstateTabs = [
        [
          'mainData',
          'Dane ogólne',
          0 === currentTabIndex,
          [`${this.service}.get_task__appraisal__1`]
        ]
      ]
      if (this.realEstate.hasOwnProperty('landRegisters') && Array.isArray(this.realEstate.landRegisters) && this.realEstate.landRegisters) {
        realEstateTabs.push(
          ...(this.realEstate.landRegisters.map((el, index) => {
            return [
              `${this.realEstate.id}-landRegister-${el.id}-${index}`,
              `Księga wieczysta ${el.id ? el.id : ''}`,
              index === currentTabIndex -1 ,
              [`${this.service}.get_task__appraisal__1`],
              true
            ]
          }))
        )
      }
      this.realEstateTabs = realEstateTabs
    },
    handleSaveField (e) {
      this.$emit(FORM_EVENT_SAVE_FIELD, e)
    },
    handleAddCollectionItem (e) {
      this.$emit(FORM_EVENT_ADD_COLLECTION_ITEM, e)
    },
    handleRemoveCollectionItem (e) {
      this.$emit(FORM_EVENT_REMOVE_COLLECTION_ITEM, e)
    },
    reactToTabChange (tabName) {
      this.realEstateTabs = this.realEstateTabs.map(tab => tab[0] === tabName ? (tab.map((tabProp, index) => index === 2 ? (tabProp = true) : tabProp)) : (tab.map((tabProp, index) => index === 2 ? (tabProp = false) : tabProp)))
    }
  }

}
</script>

<style scoped>

</style>
