<template>
  <select
    class="form-control"
    v-model="value"
    :disabled="disabled"
    :name="schema.inputName"
    :id="getFieldID(schema)"
    @change="onBlur"
    :multiple="selectOptions.multiple"
  >
    <option
      v-if="!selectOptions.hideNoneSelectedText"
      :disabled="schema.required"
      :value="null"
      :selected="value === undefined"
    >
      {{ selectOptions.noneSelectedText || "Nothing selected&gt;" }}
    </option>
    <option
      v-for="(item, optionIndex) in items"
      :value="getItemValue(item)"
      :key="`${getFieldID(schema)}-${schema.inputName}-${optionIndex}`"
    >
      {{ getItemName(item) }}
    </option>
  </select>
</template>

<script>
import { isObject } from 'lodash'
import { abstractField } from 'vue-form-generator'

export default {
  name: 'field-custom-select',
  mixins: [abstractField],
  computed: {
    selectOptions() {
      return this.schema.selectOptions || {}
    },

    items() {
      let values = this.schema.values
      if (typeof values == 'function') {
        return values.apply(this, [this.model, this.schema])
      } else {
        return values
      }
    },
  },
  beforeUpdate(){
    this.correctValueWhenMultiSelect()
  },
  created() {
    this.correctValueWhenMultiSelect()
  },
  methods: {
    correctValueWhenMultiSelect() {
      if (
        this.schema.selectOptions &&
        this.schema.selectOptions.multiple &&
        !this.value
      ) {
        this.value = []
      }
    },

    getItemValue(item) {
      if (isObject(item)) {
        if (
          typeof this.schema['selectOptions'] !== 'undefined' &&
          typeof this.schema['selectOptions']['value'] !== 'undefined'
        ) {
          return item[this.schema.selectOptions.value]
        } else {
          // Use 'id' instead of 'value' cause of backward compatibility
          if (typeof item['id'] !== 'undefined') {
            return item.id
          } else {
            throw '`id` is not defined. If you want to use another key name, add a `value` property under `selectOptions` in the schema. https://icebob.gitbooks.io/vueformgenerator/content/fields/select.html#select-field-with-object-items'
          }
        }
      } else {
        return item
      }
    },

    getItemName(item) {
      if (isObject(item)) {
        if (
          typeof this.schema['selectOptions'] !== 'undefined' &&
          typeof this.schema['selectOptions']['name'] !== 'undefined'
        ) {
          return item[this.schema.selectOptions.name]
        } else {
          if (typeof item['name'] !== 'undefined') {
            return item.name
          } else {
            throw '`name` is not defined. If you want to use another key name, add a `name` property under `selectOptions` in the schema. https://icebob.gitbooks.io/vueformgenerator/content/fields/select.html#select-field-with-object-items'
          }
        }
      } else {
        return item
      }
    },
    formatValueToModel(value) {
      return value
    },
  },
}
</script>


<style lang="sass">
</style>
