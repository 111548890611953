import { render, staticRenderFns } from "./realEstate.vue?vue&type=template&id=43bf9d67&scoped=true&"
import script from "./realEstate.vue?vue&type=script&lang=js&"
export * from "./realEstate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43bf9d67",
  null
  
)

export default component.exports