<template>
  <div class="row">
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
         v-if="$auth.isAllowed({service: this.service, action: 'get_summary_main_customer'}) && customerData">
      <CustomerData :customer-data="customerData"></CustomerData>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
         v-if="$auth.isAllowed({service: this.service, action: 'get_summary_main_credit_purpose'}) && creditPurposeData">
      <SummaryField v-if="$auth.isAllowed({service: this.service, action: 'get_summary_main_credit_purpose'})"
                    :data="creditPurposeData.creditPurpose" :label="'Wartość do określenia'"></SummaryField>

      <SummaryField :data="creditPurposeData.typeOfStudy" :label="'Rodzaj opracowania'"></SummaryField>
      <SummaryField :data="creditPurposeData.kinds" :label="'Rodzaj nieruchomości'"></SummaryField>
      <SummaryField :data="creditPurposeData.market" :label="'Rynek'"></SummaryField>
      <SummaryField :data="creditPurposeData.priority" :label="'Priorytet'"></SummaryField>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
         v-if="$auth.isAllowed({service: this.service, action: 'get_summary_main_real_estate'}) && realEstateData">
      <RealEstateAddresses :real-estate-data="realEstateData"></RealEstateAddresses>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
         v-if="$auth.isAllowed({service: this.service, action: ['get_summary_main_invoice_main', 'get_summary_main_invoice_appraisal'], allowOneOfActions: true})"
    >
      <InvoicesMain
        v-if="$auth.isAllowed({service: this.service, action: 'get_summary_main_invoice_main'}) && mainInvoicesData.length !== 0"
        :main-invoices-data="mainInvoicesData"></InvoicesMain>
      <InvoicesAppraisal
        v-if="$auth.isAllowed({service: this.service, action: 'get_summary_main_invoice_appraisal'}) && appraisalsInvoicesData.length !== 0"
        :appraisal-invoices-data="appraisalsInvoicesData"></InvoicesAppraisal>
    </div>
  </div>
</template>

<script>
import ErrorNotify from '@/components/share/mixins/ErrorNotify.js'
import CustomerData from './components/CustomerData.vue'
import RealEstateAddresses from './components/RealEstateAddresses.vue'
import InvoicesMain from './components/InvoicesMain.vue'
import InvoicesAppraisal from './components/InvoicesAppraisal.vue'
import summaryRequests from './summaryRequests.js'
import SummaryField from '@/components/bundles/intbwn/share/summary/components/SummaryField.vue'

export default {
  name: 'SummaryMain',
  components: {
    SummaryField,
    InvoicesAppraisal,
    InvoicesMain, RealEstateAddresses, CustomerData
  },
  props: {
    taskId: {default: () => null},
    taskGroup: {},
    taskData: {
      type: Object,
      default: () => ({})
    },
    service: {type: String, default: () => ''},
    summaryTabOpened: {type: Boolean}
  },
  mixins: [ErrorNotify, summaryRequests],
  watch: {
    summaryTabOpened: function (newValue, value) {
      if (newValue) {
        this.loadData()
      }
    }
  },
  data () {
    return {
      customerData: null,
      creditPurposeData: null,
      realEstateData: null,
      mainInvoicesData: [],
      appraisalsInvoicesData: []
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    loadData () {
      this.$handleCheckAndCallMethod({
        service: this.service,
        action: 'get_summary_main_customer'
      }, () => this.getCustomerData(this.taskGroup))

      this.$handleCheckAndCallMethod({
        service: this.service,
        action: 'get_summary_main_credit_purpose'
      }, () => this.getCreditPurposeData(this.taskGroup))

      this.$handleCheckAndCallMethod({
        service: this.service,
        action: 'get_summary_main_real_estate'
      }, () => this.getRealEstateData(this.taskGroup))

      this.$handleCheckAndCallMethod({
        service: this.service,
        action: 'get_summary_main_invoice_main'
      }, this.getMainInvoicesData)

      this.$handleCheckAndCallMethod({
        service: this.service,
        action: 'get_summary_main_invoice_appraisal'
      }, this.getAppraisalsInvoicesData)
    },
    handleInvoicesUpdated () {
      this.$handleCheckAndCallMethod({
        service: this.service,
        action: 'get_summary_main_invoice_main'
      }, this.getMainInvoicesData)

      this.$handleCheckAndCallMethod({
        service: this.service,
        action: 'get_summary_main_invoice_appraisal'
      }, this.getAppraisalsInvoicesData)
    }
  }
}
</script>

<style scoped>

</style>
