import _ from 'lodash'
import {
  DEFAULT_FALSE,
  FIELD_PROPERTY_DISABLED,
  FIELD_PROPERTY_FEATURED,
  FIELD_PROPERTY_REQUIRED,
  FIELD_PROPERTY_VISIBLE
} from '../utils/fieldPropertiesConstants.js'
import {
  prepareOnValidateByType,
  prepareValidatorByType
} from '../utils/validators.js'

const SORT_INFINITE_LIMIT = 8000
export default {
  data () {
    return {
      rulesTemp: {},
      rulesCache: {},
      collections: {},
      schemaLoaded: false
    }
  },
  computed: {},
  methods: {
    order (array, edgeName, edgeDependencyName) {
      let i = 0,
        j,
        temp
      let infiniteLoopPreventArrI = []
      let infiniteLoopPreventArrJ = []
      while (i < array.length && i < SORT_INFINITE_LIMIT) {
        infiniteLoopPreventArrI.push(i)
        if (infiniteLoopPreventArrI.length > SORT_INFINITE_LIMIT - 1) {
          console.error('log: infinite loop, some fields do not have parent collection', array[i])
          this.$notify({
            type: 'error',
            text: 'Nie można zbudować formularza, kolekcja jednego z pól nie istnieje'
          })
          throw new Error('infinite loop, some fields do not have parent collection')
        }
        temp = array.slice(0, i)
        for (j = i; j < array.length && j < SORT_INFINITE_LIMIT; j++) {
          if (array[j][edgeDependencyName].every(n => temp.some((field) => n === field[edgeName]))) {
            infiniteLoopPreventArrJ.push(j)

            if (infiniteLoopPreventArrJ.length > SORT_INFINITE_LIMIT - 1) {
              this.$notify({
                type: 'error',
                text: 'Nie można zbudować formularza, kolekcja jednego z pól nie istnieje'
              })
              console.error('log: infinite loop, some fields do not have parent collection', array[j])
              throw new Error('infinite loop, some fields do not have parent collection')
            }
            array.splice(i++, 0, array.splice(j, 1)[0])

            break
          }
        }
      }
      return array.reverse()
    },
    prepareSchemas (rawSchemas, schemaName) {
      const schemaObject = _.cloneDeep(rawSchemas.find(el => el.name === schemaName))
      if (schemaObject === undefined && !schemaObject.hasOwnProperty('schema')) {
        return null
      }
      let schema = this.buildSchema(schemaObject.schema)
      this.schemaLoaded = true
      return schema

    },
    buildSchema (schema) {
      const fieldMapper = (group) => group.map(field => {
        if (field.field === undefined) {
          field.field = {}
          if (field.field.settings === undefined) {
            field.field.settings = {}
          }
        }

        let obj = {...field, ...field.field, collection: field.field.settings.collection || []}
        obj.type = obj.type === 'select' ? 'customSelect' : obj.type
        obj.type = obj.type === 'collection' ? 'newCollection' : obj.type
        return obj
      })
      const collectionFields = (group) => fieldMapper(group)
      const collections = (group) => fieldMapper(group).filter(field => field.type === 'newCollection')
      const notCollectionFields = (group) => fieldMapper(group).filter(field => !field.field.settings.hasOwnProperty('collection'))

      schema.groups = schema.groups.map(group => {
          this.collections = {}
          this.prepareCollections(collections(group.fields), 'model', 'collection')
          this.prepareFields(collectionFields(group.fields), 'model', 'collection')

          return {
            legend: group.label,
            id: group.id,
            fields: this.prepareFields(notCollectionFields(group.fields))
          }
        }
      )
      return schema
    },
    getRulesProperty (path, property, defaultValue) {
      const handlePrefixPath = (el) => {
        return isNaN(parseInt(el)) ? el : `items/${el}`
      }
      let spath = `${this.pathPrefixIndexed.split('/').map(handlePrefixPath).join('/')}/${path}/${property}`
      spath = spath.slice(1, spath.length).split('/')

      return _.get(this.rules, spath, defaultValue)
    },
    setSpecificSettings (field) {
      let fieldParams = {}

      switch (field.type) {
        case 'input':
          fieldParams.inputType = field.inputType
          fieldParams.maxlength = field.settings.maxlength
          break

        case 'select':
          fieldParams.values = field.settings.values
          fieldParams.selectOptions = {hideNoneSelectedText: false, noneSelectedText: ' '}
          break

        case 'customSelect':
          fieldParams.values = field.settings.dynamicValues ? (model, schema) => this.getRulesProperty(field.model, 'selectValues', []) : field.settings.values
          fieldParams.selectOptions = {multiple: field.settings.multiple, hideNoneSelectedText: true}
          break

        case 'radios':
          fieldParams.values = field.settings.values
          break

        case 'textArea':
          fieldParams.max = field.settings.maxlength
          break
        case 'dateTimePicker':
          fieldParams.dateTimePickerOptions = field.settings.dateTimePickerOptions
          break
        case 'newCollection':
          fieldParams = this.prepareCollectionField(field)
          break
        case 'collection':
          fieldParams = this.prepareCollectionField(field)
          break
      }

      return fieldParams
    },
    prepareCollectionField (field) {
      try {
        return {
          ...field,
          legend: field.label,
          label: '',
          type: 'newCollection',
          singleObject: field.settings.singleObject || false,
          schema: {
            groups: [{
              legend: '',
              fields: [...this.collections[field.model]]
            }]
          }
        }
      } catch (e) {
        console.log(field, this.collections)
        throw new Error(e)
      }

    },
    prepareCollections (collFields) {
      return collFields.forEach(fieldObject => {
        if (fieldObject.type === 'newCollection') {
          if (!this.collections.hasOwnProperty(fieldObject.model)) {
            this.collections[fieldObject.model] = []
          }
        }
      })
    },
    setFieldProperty (field, property, path, defaultValue) {
      field[property] = (model, schema) => this.getRulesProperty(path, property, defaultValue)
    },
    setFieldProperties (field, path) {
      this.setFieldProperty(field, FIELD_PROPERTY_DISABLED, path, DEFAULT_FALSE)
      this.setFieldProperty(field, FIELD_PROPERTY_REQUIRED, path, DEFAULT_FALSE)
      this.setFieldProperty(field, FIELD_PROPERTY_VISIBLE, path, DEFAULT_FALSE)
      this.setFieldProperty(field, FIELD_PROPERTY_FEATURED, path, DEFAULT_FALSE)
    },
    prepareFields (fields) {
      return fields.map(fieldObject => {
        let path = `${fieldObject.model}`

        if (!fieldObject.hasOwnProperty('collection') || (fieldObject.hasOwnProperty('collection') && fieldObject.collection && fieldObject.collection.length === 0)) {
          this.setFieldProperties(fieldObject, path)
          if (fieldObject.type !== 'newCollection' && fieldObject.type !== 'collection') {
            fieldObject.validator = prepareValidatorByType(fieldObject)
          }
        }
        if (fieldObject.type === 'newCollection' || fieldObject.type === 'collection') {
          path = `${fieldObject.model}/metadata`
          this.setFieldProperties(fieldObject, path)
        }
        fieldObject.inputName = fieldObject.label
        fieldObject.onValidated = prepareOnValidateByType(fieldObject)
        fieldObject.getRules = this.getRulesProperty

        fieldObject = {...fieldObject, ...this.setSpecificSettings(fieldObject)}
        if (fieldObject.settings.hasOwnProperty('collection')) {
          let collections = this.collections
          if (collections.hasOwnProperty(fieldObject.settings.collection)) {
            collections[fieldObject.settings.collection].push(fieldObject)
          } else {
            collections[fieldObject.settings.collection] = [fieldObject]
          }
          this.collections = collections
        }
        return fieldObject
      })
    }
  }
}
