<template>
  <div>
    <label>FV Zlecenia głównego:</label>
    <div class="address-data-container col-xs-12 list-group"
         :key="`main-invoice-${mainInvoiceIndex}`"
         v-for="(mainInvoice, mainInvoiceIndex) in mainInvoicesData"
    >
      <div class="list-group-item list-group-item-overflowed">
        <p><label>Numer fv dla Klienta: </label> {{ mainInvoice.invoiceNumber ?  mainInvoice.invoiceNumber : 'b.d'}}</p>
        <p><label>Data wystawienia fv dla Klienta: </label> {{ mainInvoice.date ? m$formatDate(mainInvoice.date, 'YYYY-MM-DD') : 'b.d'}}</p>
        <p><label>Kwota: </label> {{mainInvoice.amount ? mainInvoice.amount : 'b.d' }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import formatDateMixin from '../../../../../share/mixins/formatDateMixin.js'

export default {
  name: 'InvoicesMain',
  props: {
    mainInvoicesData: {}
  },
  mixins: [formatDateMixin],
}
</script>

<style scoped>
.list-group-item-overflowed {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
