<template>
  <div class="">
    <input
      :id="`task-${schema.model.toLowerCase()}`"
      ref="input"
      :value="inputValue"
      type="text"
      class="form-control field-money-input"
      placeholder="0,00"
      style="display: inline-block"
      :disabled="disabled"
      @input="processValue"
      @keypress="pressingKeysGuard"
      @blur="onBlur"
    >
    <span
      class="field-money-suffix moneyField"
      style="display: inline-block"
    >
      {{ money.suffix }}
    </span>
  </div>
</template>

<script>
import { get as objGet, isFunction } from 'lodash'
import { abstractField } from 'vue-form-generator'

export default {
  name: 'fieldMoney',
  mixins: [
    abstractField
  ],
  props: {
    clear: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  data () {
    return {
      money: {
        decimal: ',',
        visualDecimal: ',',
        thousands: ' ',
        prefix: '',
        suffix: 'zł',
        precision: 2
      },
      inputValue: '',
      finalValue: '',
      lastKey: undefined,
      input: {},
      acceptedKeys: [',', 'Delete', 'Backspace', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'ArrowRight', 'ArrowLeft', 'ArrowDown', 'ArrowUp'],
      acceptedKeysOnFull: [',', 'Delete', 'Backspace', 'ArrowRight', 'ArrowLeft', 'ArrowDown', 'ArrowUp'],
      acceptedFractionKeys: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
    }
  },
  computed: {
    inputValueToBeWatched () {
      return this.model[this.schema.model]
    }
  },
  watch: {
    clear (newVal) {
      if (newVal) {
        this.inputValue = ''
      }
    },
    inputValueToBeWatched (newVal, oldVal) {
      // set initial value again, if this.model[this.schema.model] changes
      this.input.value = this.setInitValue()
    }
  },
  mounted () {
    this.input = this.$refs.input
    this.input.value = this.setInitValue()
  },
  methods: {
    setInitValue () {
      let val
      if (isFunction(this.schema.get)) {
        val = this.schema.get(this.model)
      } else if (this.model && this.schema.model) {
        val = objGet(this.model, this.schema.model)
      }

      if (typeof val === 'number') {
        val = val.toString()
      }
      if (val === null || val === undefined) {
        return ''
      }
      return this.formatCurrency(val, { inDecimal: '.' })
    },
    processValue (event) {
      let rawValue = event.target.value
      if (typeof rawValue !== 'string' && typeof rawValue !== 'number') {
        return
      }

      let cursorPosition = this.input.selectionStart
      let value = this.formatCurrency(event.target.value.toString(), { withZeros: false })
      let previousInputValue = this.inputValue
      this.$emit('input', this.formatCurrency(value, {
        outDecimal: this.money.decimal,
        withThousandSeparator: false
      }))
      this.inputValue = value
      this.saveValue()
      this.$nextTick(() => {
        this.setCursorPosition(cursorPosition, previousInputValue)
      })
    },
    updateValue (event) {

    },
    stripSpaces (value) {
      return value.replace(/\s+/g, '')
    },
    formatCurrency (rawValue, options = {}) {
      let inDecimal = options.inDecimal || this.money.visualDecimal
      let outDecimal = options.outDecimal || this.money.visualDecimal
      let withThousandSeparator = options.withThousandSeparator !== undefined ? options.withThousandSeparator : true
      let withZeros = options.withZeros !== undefined ? options.withZeros : true

      let value = this.stripSpaces(rawValue)

      if (!value) {
        return ''
      }

      let [whole, fraction] = value.split(inDecimal)

      if (withThousandSeparator) {
        whole = this.addThousandSeparator(whole)
      }

      if (withZeros) {
        if (!whole) {
          whole = '0'
        }

        fraction = fraction === undefined ? '00' : fraction.padEnd(2, '0')
      } else {
        if (value.indexOf(inDecimal) === -1) {
          return whole
        }
      }
      return `${whole}${outDecimal}${fraction}`
    },
    pressingKeysGuard (event) {
      if (event.target.value === null || event.target.value === undefined) {
        return
      }
      this.preventKeys(event)
      if (event.target.selectionStart === event.target.selectionEnd) {
        this.processBackspace(event)
        this.processDel(event)
      }
    },
    preventKeys (event) {
      let rawValue = event.target.value
      let value = this.stripSpaces(event.target.value)
      let cursorPosition = event.target.selectionStart

      if (event.target.selectionStart !== event.target.selectionEnd) {
        return
      }

      if (!this.acceptedKeys.includes(event.key)) {
        event.preventDefault()
      }

      if (value.length > 8 && value.indexOf(this.money.visualDecimal) === -1 && event.key === this.money.visualDecimal) {
        event.preventDefault()
      }

      if (value.indexOf(this.money.visualDecimal) !== -1 && event.key === this.money.visualDecimal) {
        event.preventDefault()
      }

      if (value.includes(this.money.visualDecimal)) {
        if (
          (value.split(this.money.visualDecimal)[0].length > 7 && value.length > 10) &&
            !this.acceptedKeysOnFull.includes(event.key)
        ) {
          event.preventDefault()
        }

        if (
          value.length > 10 &&
            cursorPosition === rawValue.indexOf(this.money.visualDecimal) &&
            event.key === 'Delete'
        ) {
          event.preventDefault()
        }

        if (
          value.length > 10 &&
            cursorPosition === rawValue.indexOf(this.money.visualDecimal) + 1 &&
            event.key === 'Backspace'
        ) {
          event.preventDefault()
        }

        if (value.split(this.money.visualDecimal)[1].length > 1 &&
            cursorPosition > rawValue.indexOf(this.money.visualDecimal) &&
            this.acceptedFractionKeys.includes(event.key)) {
          event.preventDefault()
        }
      } else {
        if (value.length > 8 && !this.acceptedKeysOnFull.includes(event.key)) {
          event.preventDefault()
        }
      }
      this.lastKey = event.key
    },
    processDel (event) {
      if (event.key !== 'Delete') {
        return
      }

      if (event.target.value[this.input.selectionStart] === ' ' || event.target.value[this.input.selectionStart + 1] === ' ') {
        let spliceStart = this.input.selectionStart
        event.preventDefault()
        this.removeWithThousandSeparator(event, this.input.selectionStart + 1, spliceStart)
      }
    },
    processBackspace (event) {
      if (event.key !== 'Backspace') {
        return
      }

      if (event.target.value[this.input.selectionStart - 1] === ' ') {
        let spliceStart = this.input.selectionStart - 2
        event.preventDefault()
        this.removeWithThousandSeparator(event, this.input.selectionStart - 1, spliceStart)
      }
    },
    removeWithThousandSeparator (event, cursorPos, spliceStart, spliceLength = 2) {
      let tempVal = event.target.value.split('')
      tempVal.splice(spliceStart, spliceLength)
      event.target.value = tempVal.join('')
      this.input.selectionStart = this.input.selectionEnd = cursorPos
      this.formatValue(event)
    },
    addThousandSeparator (value) {
      return value.replace(/(\d)(?=(?:\d{3})+\b)/gm, `$1${this.money.thousands}`)
    },
    setCursorPosition (orgCursorPosition, orgValue) {
      if (orgValue.length === orgCursorPosition) {
        return
      }

      let orgValueWhole = orgValue.split(this.money.visualDecimal)[0]
      let currentValueWhole = this.inputValue.split(this.money.visualDecimal)[0]
      let lengthChange = currentValueWhole.length - orgValueWhole.length

      if (Math.abs(lengthChange) > 1) {
        lengthChange = lengthChange > 0 ? lengthChange - 1 : lengthChange + 1
        let newCursorPosition = orgCursorPosition + lengthChange
        if (newCursorPosition < 0) {
          newCursorPosition = 0
        }
        this.input.selectionStart = this.input.selectionEnd = newCursorPosition
        return
      }

      this.input.selectionStart = this.input.selectionEnd = orgCursorPosition
    },
    onBlur (event) {
      if (this.inputValue !== '') {
        event.target.value = this.formatCurrency(this.inputValue)
      }

      const isCollection = this.schema.collectionIndex !== undefined
      if (isCollection) {
        this.$emit('collection-updated', this.model[this.schema.collectionName][this.schema.collectionIndex], this.schema, event.target)
      } else {
        this.$emit('field-updated', this.model[this.schema.model], this.schema, event.target)
      }
    },
    saveValue () {
      if (this.inputValue === this.finalValue && !this.inputValue) {
        return
      }

      let oldValue = this.finalValue
      this.finalValue = this.formatCurrency(this.inputValue, {
        outDecimal: this.money.decimal,
        withThousandSeparator: false
      })
      let changed = false

      if (isFunction(this.schema.set)) {
        this.schema.set(this.model, this.finalValue)
        changed = true
      } else if (this.schema.model) {
        this.setModelValueByPath(this.schema.model, this.finalValue)
        changed = true
      }

      if (changed) {
        this.$emit('model-updated', this.finalValue, this.schema.model)

        if (isFunction(this.schema.onChanged)) {
          this.schema.onChanged.call(this, this.model, this.inputValue, oldValue, this.schema)
        }

        if (this.$parent.options && this.$parent.options.validateAfterChanged === true) {
          this.validate()
        }
      }
    }
  }
}
</script>
<style scoped>
    input {
        text-align: end;
    }
</style>
