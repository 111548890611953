import api from '../../../../../api'
import ErrorNotify from '../../../../share/mixins/ErrorNotify.js'
import FieldRequirementValidator from '../mixins/FieldRequirementValidator.js'
import Loader from '../../../../share/Loader.js'
import SubSchema from '../../share/form/SubSchema.vue'
import ConfirmDeleteTabItemModal from './confirmDeleteTabItemModal/ConfirmDeleteTabItemModal.vue'
import formClient from '../../share/form/forms/formClient.js'
import _ from 'lodash'
import {FORM_ADD, FORM_REMOVE} from '../form/utils/jsonPatchContsants.js'

export default {
  name: 'schema',
  template: `
    <form v-if="isVisible && rulesLoaded">
    <ConfirmDeleteTabItemModal
      :show="confirmDeleteLandRegisterTabVisible"
      @close="() => confirmDeleteLandRegisterTabVisible = false"
      @confirm="() => handleDeleteLandRegister(tempDeleteTabData)"
    ></ConfirmDeleteTabItemModal>
    <ConfirmDeleteTabItemModal
      :show="confirmDeleteRealEstateTabVisible"
      @close="() => confirmDeleteRealEstateTabVisible = false"
      @confirm="() => handleDeleteRealEstate(tempDeleteTabData)"
    ></ConfirmDeleteTabItemModal>
    <SubSchema
      :model="model"
      :rawSchemas="rawSchemas"
      :rules="rules"
      :options="formOptions"
      :category-group="categoryGroup"
      :service="service"
      :addLandRegisterCaller="addLandRegister"
      :modelLoaded="modelLoaded"
      :topTabsDistance="topTabsDistance"
      @delete-land-register="handleDeleteLandRegisterTempPreConfirm"
      @delete-real-estate="handleDeleteRealEstateTempPreConfirm"
      @saveField="handleSubmitData"
      @addCollectionItem="handleSubmitData"
      @removeCollectionItem="handleSubmitData"
    />
    </form>`,
  components: {
    SubSchema,
    ConfirmDeleteTabItemModal
  },
  mixins: [
    Loader,
    ErrorNotify,
    FieldRequirementValidator,
    formClient
  ],
  props: {
    task: {
      type: Object, required: true, default () {
        return {}
      }
    },
    taskData: {
      type: Object, required: true, default () {
        return {}
      }
    },
    stateId: {type: Number},
    categoryId: {type: Number || String, required: true},
    taskCategorySchemas: {required: true},
    client: {type: String, required: true},
    clientUuid: {type: String, required: true},
    service: {type: String, required: true},
    categoryGroup: {type: String, required: true},
    topTabsDistance: {type: Number, required: true}
  },
  data () {
    return {
      events: {
        accidentParticipantsMakeChanged: 'schema:accidentParticipantsMakeChanged',
        addRealEstate: `${this.service}:schema:addRealEstate`,
        addLandRegister: `${this.service}:schema:addLandRegister`
      },
      fieldSendDataMap: {},
      isVisible: false,
      rulesLoaded: false,
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: false,
        fieldIdPrefix: 'task-'
      },
      model: {},
      rules: {},
      modelChanged: {},
      modelLoaded: false,
      isFirstState: true,
      schema: {
        groups: []
      },
      rawSchemas: null,
      schemaCollection: {},
      tempDeleteTabData: null,
      confirmDeleteLandRegisterTabVisible: false,
      confirmDeleteRealEstateTabVisible: false
    }
  },
  watch: {},
  created () {
    this.loadSchemas()
    this.loadData()
    this.getRules()
  },
  mounted () {
    this.initFieldsListeners()

    this.$events.on(`${this.service}:${this.categoryGroup}:refetchTaskComplete`, this.handleUpdateData)
    this.$events.on(`${this.service}:${this.categoryGroup}:refetchTask`, this.getRules)
    this.$store.commit('SET_CURRENT_TASK_MODEL', this.model)
  },
  updated () {
    this.validateRequirement()
  },
  computed: {},
  methods: {
    loadData () {
      this.model = this.taskData
      this.model = Object.entries(this.model).reduce((acc, [key, value]) => {
        switch (key) {
          case 'analyst':
            if (value !== null) {
              acc[key] = value['uuid']
              return acc
            }
            break
        }
        acc[key] = value
        return acc
      }, {})

      this.modelLoaded = true
      this.handleButtonsVisibility(this.task)
      this.$events.$emit('dashboard:submenu:taskLoaded')
    },
    loadSchemas () {
      Promise.all(this.taskCategorySchemas.map((schema) => {
        return this.loadSchema(schema.id).then(res => {
          return {
            schema: res.data,
            id: schema.id,
            name: schema.name,
            label: schema.label
          }
        })
      })).then((result) => {
        this.rawSchemas = result
        this.isVisible = true
        this.loaded = true
        this.$notify({
          type: 'success',
          text: 'Schemat formularza załadowany poprawnie'
        })
        this.isVisible = true
      }).then(() => {
        this.$events.$emit('dashboard:submenu:taskLoaded')
      })
        .catch(() => {
          this.$notify({
            type: 'error',
            text: 'Błąd ładowania schematu formularza'
          })
        })
    },
    getMetadataMethod () {
      return api.request(this.service, 'get', `/tasks/metadata/${this.task.id}`)
    },
    getRules () {
      this.getMetadataMethod().then(({data}) => {
        this.rules = data
        this.rulesLoaded = true
      }).catch((e) => {
        this.rulesLoaded = true
        this.errorNotify(e)
      })
    },
    initFieldsListeners () {
      this.$events.on(this.events.addRealEstate, this.addRealEstate)
    },
    async addRealEstate () {

      let preparedData = {
        op: FORM_ADD,
        path: '/realEstates/-',
        value: null
      }

      let submitData = {
        data: this.createDataToSend(preparedData),
        taskId: this.task.id,
        service: this.service,
        taskCategory: this.categoryGroup
      }

      this.m$submitField(submitData)
    },

    async addLandRegister ({realEstateIndex}) {
      const getRealEstate = (realEstateIndex) => this.model.hasOwnProperty('realEstates') && Array.isArray(this.model.realEstates) && this.model.realEstates[realEstateIndex] ? this.model.realEstates[realEstateIndex] : null
      const realEstateId = getRealEstate(realEstateIndex) ? getRealEstate(realEstateIndex).id : null

      let preparedData = {
        op: FORM_ADD,
        path: `/realEstates/${realEstateId}/landRegisters/-`,
        value: null,
        indexedPath: `/realEstates/${realEstateIndex}/landRegisters/-`,
      }
      let submitData = {
        data: this.createDataToSend(preparedData),
        taskId: this.task.id,
        service: this.service,
        taskCategory: this.categoryGroup
      }

      this.m$submitField(submitData)
    },
    putDeleteLandRegister ({landRegisterToDelete, realEstate}) {
      let preparedData = {
        op: FORM_REMOVE,
        path: `/realEstates/${realEstate.id}/landRegisters/${landRegisterToDelete.id}`,
        value: null
      }
      return api.request(this.service, 'patch', `tasks/${this.task.id}`, this.createDataToSend(preparedData))
    },
    handleDeleteLandRegisterTempPreConfirm ({landRegisterToDelete, realEstate}) {
      this.tempDeleteTabData = {landRegisterToDelete, realEstate}
      this.confirmDeleteLandRegisterTabVisible = true
    },
    async handleDeleteLandRegister ({landRegisterToDelete, realEstate}) {
      try {
        await this.putDeleteLandRegister({landRegisterToDelete, realEstate})
        const landRegistersToChange = this.model.realEstates.find(item => item === realEstate).landRegisters.filter(landRegister => landRegister !== landRegisterToDelete)
        const realEstateIndex = this.model.realEstates.findIndex(el => el.id === realEstate.id)
        this.$set(this.model.realEstates[realEstateIndex], 'landRegisters', landRegistersToChange)
        this.confirmDeleteLandRegisterTabVisible = false
        this.tempDeleteTabData = null

      } catch (e) {
        this.errorNotify(e)
        this.confirmDeleteLandRegisterTabVisible = false
        this.tempDeleteTabData = null
      }
    },
    handleDeleteRealEstateTempPreConfirm ({realEstate}) {
      this.tempDeleteTabData = realEstate
      this.confirmDeleteRealEstateTabVisible = true
    },
    putDeleteRealEstate (realEstate) {
      let preparedData = {
        op: FORM_REMOVE,
        path: `/realEstates/${realEstate.id}`,
        value: null
      }
      return api.request(this.service, 'patch', `tasks/${this.task.id}`, this.createDataToSend(preparedData))
    },
    async handleDeleteRealEstate (realEstateToDelete) {
      try {
        await this.putDeleteRealEstate(realEstateToDelete)
        const realEstatesToChange = this.model.realEstates.filter(realEstate => realEstate !== realEstateToDelete)
        this.$set(this.model, 'realEstates', realEstatesToChange)
        this.confirmDeleteRealEstateTabVisible = false
        this.tempDeleteTabData = null
      } catch (e) {
        this.errorNotify(e)
        this.confirmDeleteRealEstateTabVisible = false
        this.tempDeleteTabData = null
      }
    },
    handleButtonsVisibility (task) {
      if (task.state) {
        this.$events.$emit(`dashboard:submenu:${this.categoryGroup}TaskInInitialState`, task.state.initial)
        if (!task.state.initial && !task.state.final) {
          this.$events.$emit('dashboard:submenu:showAll')
        }
      }
    },
    getLoadSchemaMethod (schemaId) {
      return api.request(this.service, 'get', `/form-schemas/${schemaId}`)
    },
    loadSchema (schemaId) {
      return this.getLoadSchemaMethod(schemaId)
    },
    preparePropertyPath (data) {
      return data.indexedPath ? data.indexedPath.slice(1, data.indexedPath.length).split('/') : data.path.slice(1, data.path.length).split('/')
    },
    handleUpdateData (data) {
      let propertyPath = this.preparePropertyPath(data.field)
      propertyPath = propertyPath.filter(el => el !== '-')

      if (data.field.op === FORM_ADD) {
        _.set(this.model, propertyPath, _.get(data.data, propertyPath))
      }
      if (data.field.op === FORM_REMOVE) {
        _.set(this.model, propertyPath, _.get(data.data, propertyPath))
      }
      this.$emit('updateTaskData', this.model, propertyPath)
      if (data.field.relatedFields) {
        data.field.relatedFields.forEach(field => {
          this.$emit('updateTaskData', this.model, field)
        })
      }
    },
    checkChangedFieldValue (e) {
      return _.get(this.taskData, this.preparePropertyPath(e)) !== e.value
    },
    handleSubmitData (e) {
      this.validateRequirement()
      //
      // if (!this.checkChangedFieldValue(e)) {
      //   return
      // }

      let submitData = {
        data: this.createDataToSend(e),
        taskId: this.task.id,
        service: this.service,
        taskCategory: this.categoryGroup,
        target: e.target
      }

      this.m$submitField(submitData)
    },
    createDataToSend (data) {
      let dataToSend = {
        op: data.op,
        value: data.value,
        path: data.path,
        indexedPath: data.indexedPath,
        relatedFields: data.relatedFields || []
      }
      dataToSend[this.categoryGroup] = true
      dataToSend['client'] = this.client
      dataToSend['category'] = this.categoryId
      return dataToSend
    }
  }
}
