<template>
  <ul
    v-if="relatedTasks && relatedTasks.length > 0"
    class="list-unstyled task-header--details row"
  >
    <li
      class=""
      style="display: flex;"
    >
      <label
        class="align-right"
        style="width: 12.5%; padding: 0 20px;"
      >Powiązane: </label>
      <span
        :id="`${task.id}-relatedTasks`"
        style="width: 77.5%;"
      >
            <template
              v-for="(relatedTask, index) in relatedTasks"
            >
              <popover
                v-if="relatedTasks && relatedTasks.length > 0"
                placement="bottom"
                :title="`${relatedTask.number || relatedTask.id}`"
                trigger='hover'
                :key="`popver-${relatedTask.id}`"
              >
              <span
                v-if="relatedTasks && relatedTasks.length > 0"
                :key="relatedTask.id"
                :id="`span${task.id}-related-task-${relatedTask.id}`"
              >
              <a
                v-if="relatedTasks.length === 1"
                :id="`${task.id}-related-task-link-${relatedTask.number || relatedTask.id}`"
                class="related-task-link"
                @click="redirectToRelatedTask(relatedTask.id)"
              > {{ relatedTask.number || relatedTask.id }} </a>
              <a
                v-if="relatedTasks.length > 1"
                :id="`${task.id}-related-task-link-${relatedTask.number || relatedTask.id}`"
                class="related-task-link"
                @click="redirectToRelatedTask(relatedTask.id)"
              > {{ relatedTask.number || relatedTask.id }}</a>{{ getComa(index) }}
            </span>
                <template slot="popover">
                    <ul style="list-style: none">
                      <li v-if="relatedTask.author"><label>Autor:</label> {{ relatedTask.author.label }}</li>
                      <li v-if="relatedTask.createdAt"><label>Utworzono:</label> {{ relatedTask.createdAt }}</li>
                    </ul>
                  </template>
               </popover>
              <span v-if="relatedTasks.length === 0" :key="relatedTask.id">b.d.</span>
            </template>

          </span>
    </li>
    <li class="col-xs-12"/>
  </ul>

</template>

<script>
import {Popover} from 'uiv'

export default {
  name: 'RelatedTasks',
  props: {
    relatedTasks: {type: Array, default: () => []},
    service: {type: String, default: () => ''},
    workflowName: {type: String, default: () => ''},
    task: {
      type: Object, default: () => {
      }
    }
  },
  components: {
    popover: Popover
  },
  computed: {
    client () {
      return this.$route.params.client
    }
  },
  methods: {
    redirectToRelatedTask (id) {
      this.isComplaint(this.task) ?
        this.$router.push({
          name: `${this.service}_task_details`,
          params: {id, service: this.service, client: this.client, taskCategoryId: '1'}
        })
        : this.$router.push({
          name: `complaint`,
          params: {id, service: this.service, client: this.client, taskCategoryId: 3}
        })
    },
    getComa (index) {
      if (index === this.relatedTasks.length - 1) {
        return '.'
      } else {
        return ','
      }
    },
    isComplaint (task) {
      return task.category.group === 'complaint'
    }
  }
}
</script>

<style scoped>

</style>
