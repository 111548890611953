<template>
  <base-form-modal
    :show="show"
    :title="title"
    :ok-text="okText"
    :cancel-text="cancelText"
    @show="open"
    @close="$emit('close')"
    @beforeSubmit="bool => beforeSubmitTrigger = bool"
  >
    <form-modal ref="form" v-model="model" :is-before-submit="beforeSubmitTrigger" />
  </base-form-modal>
</template>

<script>
import BaseFormModal from '../../../../share/modal/BaseFormModal.vue'
import FormModalMixin from '../../../../share/modal/FormModalMixin.js'
import FormModal from './ChangeStateOtherForm.vue'
import api from '../../../../../api'
import ValidatorReset from '../../../../share/mixins/ValidatorReset.js'
import ErrorNotify from "@/components/share/mixins/ErrorNotify.js";

export default {
name: "ChangeStateOtherModal",
  components: {
    FormModal,
    BaseFormModal
  },
  mixins: [
    FormModalMixin,
    ValidatorReset,
    ErrorNotify
  ],
  props: {
    service: { type: String, required: true, default: '' },
    taskType: { type: String, default: 'main' }
  },
  data () {
    return {
      okText: 'Wyślij',
      sending: false,
      title: 'Wysyłanie wiadomości do banku',
    }
  },
  methods: {
    submitForm () {
      let id = (this.taskType === 'appraisal') ? this.$route.params.appraisalTaskId : this.$route.params.id
      this.submitChangeStateOther(id)
    },
    createDataToSend () {
      return this.model
    },
    submitChangeStateOther (id) {
      if (this.sending === true) {
        this.$notify({
          type: 'info',
          title: 'Trwa wysyłanie wiadomości, proszę czekać',
          text: ''
        })
        return
      }
      this.sending = true
      api.request(this.service, 'put', `/tasks/${id}/millennium-status`, this.createDataToSend())
        .then((response) => {
          this.$notify({
            type: 'success',
            text: 'Wysłano wiadomość do banku'
          })
          this.$emit('success')
          this.$emit('close')
          this.sending = false
        })
        .catch((e) => {
          this.sending = false
          this.errorNotify(e)
        })
    }
  }
}

</script>

<style scoped>

</style>
