<template>
  <base-form-modal
    :show="show"
    :title="title"
    :ok-text="okText"
    :cancel-text="cancelText"
    @show="open"
    @close="$emit('close')"
    @beforeSubmit="bool => beforeSubmitTrigger = bool"
  >
    <div class="form-group">
      <select-type
        v-model="commentType"
        name="commentType"
        :label="$t('pages.taskDetails.comments.modal.selectLabel')"
        :required="true"
        :options="commentTypes"
        :empty-first="false"
        :service="service"
      />
    </div>
    <form-modal ref="form" v-model="model" :is-before-submit="beforeSubmitTrigger" />
  </base-form-modal>
</template>

<script>
import BaseFormModal from '../../../../share/modal/BaseFormModal.vue'
import FormModalMixin from '../../../../share/modal/FormModalMixin.js'
import FormModal from './Comment.vue'
import api from '../../../../../api'
import ValidatorReset from '../../../../share/mixins/ValidatorReset.js'
import SelectType from '../../../../share/form/type/SelectType.vue'
import WebStorage from '../../../../share/WebStorage.js'

export default {
  components: {
    SelectType,
    FormModal,
    BaseFormModal
  },
  mixins: [
    FormModalMixin,
    ValidatorReset
  ],
  props: {
    service: { type: String, required: true, default: '' },
    taskType: { type: String, default: 'main' }
  },
  data () {
    return {
      sending: false,
      title: this.$t('pages.taskDetails.comments.modal.addModalTitle'),
      commentType: null,
      commentTypes: [],
      commentTypesOptions: [],
      trans: {
        private: { value: 1, label: this.$t('pages.taskDetails.comments.modal.private'), type: 'private' },
        internal: { value: 2, label: this.$t('pages.taskDetails.comments.modal.internal'), type: 'internal' },
        public: { value: 4, label: this.$t('pages.taskDetails.comments.modal.public'), type: 'public' }
      },
      transBLS: {
        private: { value: 'private', label: this.$t('pages.taskDetails.comments.modal.private'), type: 'private' },
        internal: { value: 'internal', label: this.$t('pages.taskDetails.comments.modal.internal'), type: 'internal' },
        public: { value: 'public', label: this.$t('pages.taskDetails.comments.modal.public'), type: 'public' }
      }
    }
  },
  mounted () {
    this.getCommentTypes()
  },
  methods: {
    getCommentTypes () {
      const actions = WebStorage.getSecurityActions()

      if (this.$isWithClients(this.service)) {
        this.commentTypes = actions[this.service].filter(action => this.taskType === 'main' ? action.includes('post_comment__main') : action.includes('post_comment__appraisal')).map((action) => {
          return this.transBLS[action.split('_')[4]]
        })
        this.commentType = this.commentTypes[0].type
      } else {
        this.commentTypes = actions[this.service].filter(action => this.taskType === 'main' ? action.includes('post_comment_main') : action.includes('post_comment_appraisal')).map((action) => {
          return this.trans[action.split('_')[3]]
        })
        this.commentType = this.commentTypes[0].value
      }
      // Set initial model value of first select item
    },
    submitForm () {
      let id = (this.taskType === 'appraisal') ? this.$route.params.appraisalTaskId : this.$route.params.id
      let comment = this.createDataToSend()
      this.submitCommentClients(id, comment)
    },
    submitCommentClients (id, comment) {
      if (this.sending === true) {
        this.$notify({
          type: 'info',
          title: this.$t('pages.taskDetails.comments.modal.commentSuccesfullyAddeed'),
          text: ''
        })
        return
      }
      this.sending = true
      let data = {
        taskId: id,
        content: this.model.message
      }
      data[`${this.commentType}`] = true
      data[`${this.taskType}`] = true
      api.request(this.service, 'post', `/comments`, data)
        .then((response) => {
          this.$notify({
            type: 'success',
            text: this.$t('pages.taskDetails.comments.modal.commentSuccesfullyAddeed')
          })
          let location = response.headers.location.split('/')
          let commentId = location[location.length - 1]
          comment.id = commentId
          this.$emit('success')
          this.$emit('close')
          this.$events.$emit('addComment', comment)
          this.sending = false
        })
        .catch(() => {
          this.sending = false
          this.$notify({
            type: 'error',
            title: this.$t('error.default'),
            text: this.$t('pages.taskDetails.comments.modal.commentSendingError')
          })
        })
    },
    createDataToSend () {
      return {
        type: this.commentType,
        content: this.model.message
      }
    }
  }
}
</script>
