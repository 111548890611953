<template>
  <div>
    Czy na pewno chcesz usunąć tę zakładkę?
  </div>
</template>

<script>

import FormModalValidation from '@/components/share/form/FormModalValidation.js'

export default {
  mixins: [
    FormModalValidation
  ],
  props: {
    value: { type: Object, required: true }
  }
}
</script>
