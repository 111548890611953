<template>
  <div>
    <h2 class=" tab-header">
      Podzlecenia
    </h2>
    <div
      v-if="preparedDataToList.length !== 0"
      class=""
    >
      <grid
        :service="service"
        :content="preparedDataToList"
        :name="gridName"
        :columns="gridColumns"
        :actions="gridActions"
        :searchable="false"
        :server-side="false"
        @show="actionShow"
      />
    </div>
    <div
      v-else
      class="content-empty"
    >
      Nie ma żadnych podzleceń
    </div>
  </div>
</template>
<script>
  import Grid from '../../../share/grid/Grid.vue'
  import WebStorage from '../../../../share/WebStorage.js'

  export default {
    components: {
      Grid
    },
    props: {
      service: {type: String, required: true},
      subtasks: {type: Array},
      task: {type: Object}
    },
    data () {
      return {
        gridName: 'grid_appraisal_tasks',
        gridColumns: [
          {id: 1, data: 'number', title: 'Numer podzlecenia', searchable: false},
          {id: 1, data: 'type', title: 'Typ zlecenia', searchable: false},
          {id: 3, data: 'status', title: 'Status', searchable: false},
          {id: 4, data: 'assignee', title: 'Opiekun', searchable: false},
          {id: 5, data: 'createdAt', title: 'Data utworzenia', searchable: false}
        ]
      }
    },
    computed: {
      client () {
        return this.$route.params.client || this.$route.params.clientSymbol
      },
      gridActions () {
        return [
          {
            id: 0,
            event: 'show',
            title: 'Szczegóły',
            icon: 'eye',
            params: ['parentId', 'supportedClass', 'appraisalTaskCategory', 'categoryGroup'],
            // acl: { service: this.service, action: this.$isWithClients(this.service) ? `get_task__appraisal__${this.client}` : 'get_appraisal_task' }
            acl: {security: false}
          }
        ]
      },
      preparedDataToList () {
        return this.subtasks.map(subtask => {
          return {
            id: subtask.id,
            supportedClass: subtask.supportedClass,
            number: subtask.number,
            type: subtask.category.label,
            category: subtask.category.name,
            categoryGroup: subtask.category.group,
            status: subtask.state.label,
            assignee: subtask.assignee ? subtask.assignee.fullname ? subtask.assignee.fullname : subtask.assignee.label : '',
            createdAt: subtask.createdAt,
            parentId: this.task.id,
            appraisalTaskCategory: subtask.category.id
          }
        })
      }
    },
    created () {
      this.getInitialGridColumns()
    },
    methods: {
      getInitialGridColumns () {
        let actions = WebStorage.getSecurityActions()
        this.gridColumns = [
          {id: 1, data: 'number', title: 'Numer podzlecenia', searchable: false},
          {id: 2, data: 'type', title: 'Typ zlecenia', searchable: false},
          {id: 3, data: 'status', title: 'Status', searchable: false},
          {id: 4, data: 'assignee', title: 'Opiekun', searchable: false},
          {id: 5, data: 'createdAt', title: 'Data utworzenia', searchable: false},
        ].filter(column => {
          if (column.data === 'bid') {
            if (actions[this.service].includes('frontend_acl_get_bid_column')) {
              return column
            }
          } else {
            return column
          }
        })
      },
      actionShow (id, params) {
        const subtaskRouteMethods = {
          'appraisal': () => this.$router.push({
              name: `${this.service}_appraisal_task_details`,
              params: {id: params.parentId, appraisalTaskId: id, appraisalTaskCategory: params.appraisalTaskCategory}
            }),
          'complaint': () => this.$router.push({
            name: `complaint`,
            params: {service: this.service, client: this.client, taskId: id, taskCategoryId: params.appraisalTaskCategory}
          }),
        }
        if (params && params.parentId) {
          subtaskRouteMethods[params.categoryGroup]()
        }
      }
    }
  }
</script>
