<template>
  <div class="list-group">
    <label>FV podzleceń:</label>
    <div class="address-data-container col-xs-12 list-group-item"
         :key="`main-invoice-${appraisalInvoiceIndex}`"
         v-for="(appraisalInvoice, appraisalInvoiceIndex) in appraisalInvoicesData"
    >
      <label for="address-purpose-data">FV podzlecenia: {{appraisalInvoice.taskNumber}}</label>
      <InvoiceAppraisal :appraisal-invoice="appraisalInvoice" :appraisal-invoice-index="appraisalInvoiceIndex"/>
    </div>
  </div>
</template>

<script>
import formatDateMixin from '../../../../../share/mixins/formatDateMixin.js'
import InvoiceAppraisal from './InvoiceAppraisal.vue'
export default {
name: "InvoicesAppraisal",
  components: {InvoiceAppraisal},
  props: {
    appraisalInvoicesData: {}
  },
  mixins: [formatDateMixin],
}
</script>

<style scoped>
.list-group-item-overflowed {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
