<template>
  <div
    :id="'taskCategory-multiselect'"
  >
    <form>
      <div class="form-group" :class="{'has-error': vErrors.has('taskCategory') }" style="margin-bottom: 2px;">
        <label >Rodzaj zlecenia:</label>
      </div>
      <vue-multiselect
        :id="'input-taskCategory-multiselect'"
        v-validate="'required'"
        class="assignee-multiselect"
        :name="'taskCategory'"
        v-model="value.taskCategory"
        :label="'label'"
        :select-label="'Wybierz'"
        :selected-label="'Wybrano'"
        :deselect-label="'Odznacz'"
        :options="taskCategories"
        data-vv-as="'Rodzaj zlecenia'"
        :placeholder="'Wybierz/Szukaj'"
      >
        <template slot="noResult">
          <p>Brak wyników</p>
        </template>
        <template slot="noOptions">
          <p>Brak wyników</p>
        </template>
      </vue-multiselect>
      <div v-if="taskCategories.length === 0">
        <label>Brak rodzajów zlecenia do przypisania</label>
      </div>
    </form>
    <div
      class="form-group"
      :class="{'has-error': vErrors.has('taskCategory') }"
    >
      <label>{{ vErrors.first('taskCategory') }}</label>
    </div>
  </div>
</template>

<script>
  import Loader from '../../../../../share/Loader.js'
  import FormModalValidation
    from '../../../../../share/form/FormModalValidation.js'
  import VueMultiselect from 'vue-multiselect'

  export default {
    components: {
      VueMultiselect
    },
    mixins: [
      Loader,
      FormModalValidation
    ],
    props: {
      open: { type: Boolean, required: true, default: false },
      value: { type: Object, default: () => {} },
      taskId: { type: Number, default: 0 },
      service: { type: String, required: true },
      taskCategories: { type: Array, required: true }
    },
    data () {
      return {
      }
    },
    watch: {
    },
    methods: {

    }
  }
</script>
