<template>
  <base-form-modal
    :show="show"
    :title="title"
    :ok-text="okText"
    :cancel-text="cancelText"
    @show="open"
    @close="$emit('close')"
    @beforeSubmit="bool => beforeSubmitTrigger = bool"
  >
    <form-modal
      ref="form"
      v-model="model"
      :is-before-submit="beforeSubmitTrigger"
      :open="show"
      :task-id="taskId"
      :service="service"
      :task-categories="taskCategories"
    />
  </base-form-modal>
</template>

<script>
  import BaseFormModal from '../../../../share/modal/BaseFormModal.vue'
  import FormModalMixin from '../../../../share/modal/FormModalMixin.js'
  import FormModal from './form/AddAppraisalTaskTypeForm.vue'
  import api from '../../../../../api'

  export default {
    components: {
      FormModal,
      BaseFormModal
    },
    mixins: [
      FormModalMixin
    ],
    props: {
      clientObject: {type: Object, required: true},
      taskId: {required: true},
      service: { type: String, required: true },
      client: {type: String, required: true}
    },
    data () {
      return {
        title: 'Dodaj podzlecenie',
        okText: 'Dodaj',
        taskCategories: []
      }
    },
    mounted () {
      this.getAppraisalTaskCategories()
    },
    methods: {
      validate (e) {
        console.log('validate', e)
      },
      async submitForm () {
        if (!this.model.taskCategory) {
          await this.$notify({
            type: 'info',
            title: '',
            text: 'Musisz wybrać rodzaj zlecenia.'
          })
          return
        }
        try {
          let response = await api.request(this.service, 'post', `tasks`,
            this.createDataToSend())
            let location = response.headers.location.split('/')
            await this.$router.push({
              name: `${this.service}_appraisal_task_details`,
              params: { id: this.taskId, appraisalTaskId: location[location.length - 1], appraisalTaskCategory: this.model.taskCategory.id }
          })
        } catch (e) {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można dodać zlecenia'
          })
          console.error(e)
        }
      },
      createDataToSend () {
        let data = {}
        data = {
          parentId: this.$route.params.id,
          type: this.model.taskCategory.name,
          clientUuid: this.clientObject.uuid,
          categoryId: this.model.taskCategory.id
        }
        data[this.model.taskCategory.name] = true
        data[this.model.taskCategory.group] = true
        data['categoryId'] = this.model.taskCategory.id
        data['client'] = this.client
        return data
      },
      async getAppraisalTaskCategories () {
        try {
          let {data} = await api.request(this.service, 'get', `task-categories?group=appraisal`)
          this.taskCategories = data
        } catch (e) {
          console.error(e)
        }
      }
    }
  }
</script>
