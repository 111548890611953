<template>
  <base-form-modal
    :show="show"
    :title="title"
    :ok-text="okText"
    :cancel-text="cancelText"
    @show="open"
    @close="$emit('close')"
    @beforeSubmit="bool => beforeSubmitTrigger = bool"
  >
    <form-modal ref="form" v-model="model" :is-before-submit="beforeSubmitTrigger" />
  </base-form-modal>
</template>

<script>
import BaseFormModal from '../../../../../share/modal/BaseFormModal.vue'
import FormModalMixin from '../../../../../share/modal/FormModalMixin.js'
import FormModal from './ConfirmDeleteTabItemForm.vue'

export default {
  components: {
    FormModal,
    BaseFormModal
  },
  mixins: [
    FormModalMixin,
  ],
  props: {
  },
  data () {
    return {
      title: 'Potwierdź usunięcie zakładki',
      okText: 'Tak',
      cancelText: 'Nie'
    }
  },
  mounted () {
  },
  methods: {
    submitForm () {
      this.$emit('confirm')
    },
  }
}
</script>
