<template>
  <form>
    <div
      class="form-group required-field"
      :class="[{ 'has-error': vErrors.first('content'), 'has-success' : !vErrors.first('content') && value['content'] }]"
    >
      <label for="content">
        {{$t('pages.taskDetails.comments.modal.content')}}
      </label>
      <textarea
        id="content"
        v-model="value['content']"
        v-validate="{rules: {required: true, max: 1024}}"
        name="content"
        class="form-control"
        cols="60"
        rows="6"
        :placeholder="$t('pages.taskDetails.comments.modal.placeholder')"
        required="true"
        :data-vv-as="$t('pages.taskDetails.comments.modal.dataVvAs')"
      />
      <span
        v-show="vErrors.has('content')"
        class="help-block"
      >{{ vErrors.first('content') }}</span>
    </div>
  </form>
</template>

<script>
import FormModalValidation from '../../../../share/form/FormModalValidation.js'

export default {
  mixins: [
    FormModalValidation
  ],
  props: {
    value: { type: Object, required: true }
  }
}
</script>
