import {isValueFalsyNotFalse} from '../form/utils/validators.js'

export default {
  methods: {
    validateRequirement () {
      this.validateRequiredFields()
    },
    validateRequiredFields () {
      this.toggleRequiredFieldsErrorStyle()
      this.$emit('form-validity', this.validateForm().length === 0)
    },
    validateForm () {
      return this.toggleRequiredFieldsErrorStyle().map(field => field.name)
    },
    getRequiredFields () {
      return [...document.querySelectorAll('.task-form .required')]
    },
    showRequiredFields (requiredFields) {
      this.$notify({
        type: 'error',
        text: this.$t('pages.taskDetails.formValidationError', {data: `${requiredFields.join(', ')}.`}),
        duration: -1
      })
      return requiredFields
    },
    toggleRequiredFieldsErrorStyle () {
      let requiredFields = this.getRequiredFields()
      const toggleBorder = (field) => {
        if (field.value === '') {
          field.setAttribute('style', 'border: 1px solid #f65c43')
          return field
        }
        field.removeAttribute('style', 'border')
        return field
      }
      let fieldsToShow = requiredFields.map(field => [...field.querySelectorAll('input, select, textarea')]
        .filter(field => field.value !== 'undefined' || (Array.isArray(field) && field.length !== 0))).filter(field => field[0])
        .map(field => {
          field.forEach(el => toggleBorder(el))
          return field[0]
        }).filter(field => isValueFalsyNotFalse(field.value)).map(field => {
          const invalidEvent = new Event('invalid', { bubbles: true })
          field.dispatchEvent(invalidEvent)
          return field
        })
      return fieldsToShow
    }
  }
}
